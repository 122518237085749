import AuthRepository from './core/auth-repository';
import UserAccountRepository from './core/user-accounts-repository';
import UserAdRepository from './core/users-ad-repository';
import NotificationRepository from './core/notifications-repository';
import CoreCompanyRepository from './core/companies-repository';
import CoreReportRepository from './core/report-repository';
import PaymentApplicationRepository from './payments/applications-repository';
import PaymentCompanyRepository from './payments/companies-repository';
import PaymentBankRepository from './payments/banks-repository';
import PaymentBankAccountRepository from './payments/bank-accounts-repository';
import PaymentPrepaidCardsRepository from './payments/prepaid-cards-repository';
import AttachmentRepository from './payments/attachments-repository';
import PaymentApplicationAttachmentRepository from './payments/applications-attachments-repository';
import PaymentReportRepository from './payments/reports-repository';
import PaymentContactRepository from './payments/contacts-repository';
import PaymentBankFileTemplatesRepository from './payments/bank-file-templates-repository';
import PaymentExternalResponsesRepository from './payments/external-responses-repository';

const repositories = {
  'AuthRepository': AuthRepository,
  'UserAccountRepository': UserAccountRepository,
  'UserAdRepository': UserAdRepository,
  'NotificationRepository': NotificationRepository,
  'CoreCompanyRepository': CoreCompanyRepository,
  'CoreReportRepository': CoreReportRepository,
  'PaymentApplicationRepository': PaymentApplicationRepository,
  'PaymentCompanyRepository': PaymentCompanyRepository,
  'PaymentBankRepository': PaymentBankRepository,
  'PaymentBankAccountRepository': PaymentBankAccountRepository,
  'PaymentPrepaidCardsRepository': PaymentPrepaidCardsRepository,
  'AttachmentRepository': AttachmentRepository,
  'PaymentApplicationAttachmentRepository': PaymentApplicationAttachmentRepository,
  'PaymentReportRepository': PaymentReportRepository,
  'PaymentContactRepository': PaymentContactRepository,
  'PaymentBankFileTemplatesRepository': PaymentBankFileTemplatesRepository,
  'PaymentExternalResponsesRepository': PaymentExternalResponsesRepository,
};

export default {
  get: name => repositories[name]
};
