import Client from '../axios-client';

const resource = '/payments/applications';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getGrid(parameters) {
    return Client.get(`${resource}/grid` + parameters);
  },
  getSchedule(id) {
    return Client.get(`${resource}/${id}/cycle-of-applications`);
  },
  updateItem(payload, id) {
    return Client.put(`${resource}/${id}`, payload);
  },
  downloadBankFile(applicationId) {
    return Client.get(`${resource}/${applicationId}/download-bank-file`, {responseType: 'blob'});
  },
  generateBankFile(parameters, payload) {
    return Client.post(`${resource}/generate-bank-file` + (parameters ? parameters : ''), payload);
  },
  getBankFileTypes(applicationId) {
    return Client.get(`${resource}/${applicationId}/bank-file-types`);
  },
};
