import Client from '../axios-client';

const resource = '/core/user-accounts/users-ad';

export default {
  search(searchText) {
    const searchParam =
      "?search=" + searchText;
    return Client.get(`${resource}` + searchParam);
  }
};
