import Client from '../axios-client';

const resource = '/payments/attachments';

export default {
  getResourcePath() {
    return Client.defaults.baseURL + resource;
  },
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  download(id) {
    return Client.get(`${resource}/${id}/download`, {responseType: 'blob'});
  },
  getForApplication(applicationId) {
    return Client.get(`${resource}/applications/${applicationId}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(payload, id) {
    return Client.put(`${resource}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};
