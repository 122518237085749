import Client from '../axios-client';

const resource = '/payments/reports';

export default {
  getRealisedApplications(parameters) {
    return Client.get(`${resource}/realized-applications` + parameters);
  },
  getAttachedTransferConfirmations(parameters) {
    return Client.get(`${resource}/attached-transfer-confirmations` + parameters);
  },
};