import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import "@/design/index.scss";
import Sparkline from 'vue-sparklines';
import ElementUI from 'element-ui';
import JsonExcel from "vue-json-excel";
import VueCurrencyFilter from 'vue-currency-filter';
import './element-variables.scss'
import locale from 'element-ui/lib/locale/lang/en'
import './filters.js';

Vue.use(BootstrapVue);
Vue.use(ElementUI, { locale })

if(process.env.NODE_ENV !== 'development') {
  Vue.config.productionTip = false;
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueCurrencyFilter,
  {
    name: 'currencyFilter',
    symbol : '',
    thousandsSeparator: ' ',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'end',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  })
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
