import store from '@/state/store'

export default [
  {
    path: '/',
    meta: { authRequired: true },
    name: 'home',
    component: () => import('./views/home'),
  },
  {
    path: '/auth/social-callback/:code',
    name: 'Social social-callback',
    component: () => import('./views/social-callback'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/login/:error?',
    name: 'login',
    component: () => import('./views/login1'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/login-local-user/:error?',
    name: 'login-local-user',
    component: () => import('./views/login-local-user'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/change-password/:token',
    name: 'change-password',
    component: () => import('./views/change-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/my-profile',
    name: 'Mój profil',
    component: () => import('./views/user-accounts/account-my-profile'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/profile/:id',
    name: 'Profil użytkownika',
    component: () => import('./views/user-accounts/account-profile'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/account/add',
    name: 'Dodaj użytkownika',
    component: () => import('./views/user-accounts/account-add'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/account/edit/:id',
    name: 'Edycja użytkownika',
    component: () => import('./views/user-accounts/account-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/account/list',
    name: 'Lista użytkowników',
    component: () => import('./views/user-accounts/account-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/details/:uuid',
    name: 'applications-details',
    component: () => import('./views/applications/details'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_FINANCIAL_OPERATIONS") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_SUPER_ACCEPTING")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/details/edit/:uuid',
    name: 'applications-details-edit',
    component: () => import('./views/applications/details-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_FINANCIAL_OPERATIONS")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/details/change-status/:uuid',
    name: 'applications-details-change-status',
    component: () => import('./views/applications/details-change-status'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_FINANCIAL_OPERATIONS")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/company/list',
    name: 'Lista firm',
    component: () => import('./views/companies/company-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/company/add',
    name: 'Dodaj firmę',
    component: () => import('./views/companies/company-add'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/company/edit/:id',
    name: 'Edycja firmy',
    component: () => import('./views/companies/company-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/contacts/list',
    name: 'Lista kontaktów',
    component: () => import('./views/contacts/contact-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/contacts/add',
    name: 'Dodaj kontakt',
    component: () => import('./views/contacts/contact-add'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/contacts/edit/:id',
    name: 'Edycja kontaktu',
    component: () => import('./views/contacts/contact-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank-file-templates/list',
    name: 'Lista szablonów plików bankowych',
    component: () => import('./views/bank-file-templates/bank-file-templates-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank-file-templates/add',
    name: 'Dodaj szablon pliku bankowego',
    component: () => import('./views/bank-file-templates/bank-file-templates-add'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank-file-templates/edit/:id',
    name: 'Edycja szablonu pliku bankowego',
    component: () => import('./views/bank-file-templates/bank-file-templates-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/external-responses/list',
    name: 'Lista odpowiedzi interfejsów',
    component: () => import('./views/external-responses/external-responses-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank/list',
    name: 'Lista banków',
    component: () => import('./views/banks/bank-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank/add',
    name: 'Dodaj bank',
    component: () => import('./views/banks/bank-add'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank/edit/:id',
    name: 'Edycja banku',
    component: () => import('./views/banks/bank-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank-accounts/list',
    name: 'Lista rachunków bankowych',
    component: () => import('./views/bank-accounts/bank-accounts-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_FINANCIAL_OPERATIONS")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank-accounts/add',
    name: 'Dodaj rachunek bankowy',
    component: () => import('./views/bank-accounts/bank-accounts-add'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/bank-accounts/edit/:id',
    name: 'Edycja rachunku bankowego',
    component: () => import('./views/bank-accounts/bank-accounts-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/prepaid-cards/list',
    name: 'Lista kart',
    component: () => import('./views/prepaid-cards/prepaid-cards-list'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/prepaid-cards/add',
    name: 'Dodaj kartę',
    component: () => import('./views/prepaid-cards/prepaid-cards-add'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/prepaid-cards/edit/:id',
    name: 'Edycja karty',
    component: () => import('./views/prepaid-cards/prepaid-cards-edit'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_PRODUCT_ADMINISTRATOR")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/reports/realised-applications',
    name: 'Liczba zrealizowanych przelewów',
    component: () => import('./views/reports/reports-realised-applications'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/reports/attached-transfer-confirmation',
    name: 'Potwierdzenia przelewów',
    component: () => import('./views/reports/reports-attached-transfer-confirmation'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/reports/app-logins',
    name: 'Historia logowania',
    component: () => import('./views/reports/reports-app-logins'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_ADMIN")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/notifications/:type/:module',
    name: 'Moje powiadomienia',
    component: () => import('./views/notifications/notifications'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('./views/errors/error-404'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/errors/404',
    name: 'error-404',
    component: () => import('./views/errors/error-404'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/errors/500',
    name: 'error-500',
    component: () => import('./views/errors/error-500'),
    meta: {
      authRequired: true,
    }
  },
]
