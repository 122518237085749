import Client from '../axios-client';

const resource = '/payments/prepaid-cards';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getGrid(parameters) {
    return Client.get(`${resource}/grid` + parameters);
  },
  getAll() {
    return Client.get(`${resource}/`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(payload, id) {
    return Client.put(`${resource}/${id}`, payload);
  },
  close(payload, id) {
    return Client.put(`${resource}/${id}/close`, payload);
  },
};
