import axiosClient from '@/app/repository/axios-client';
import Repository from "@/app/repository/repository-factory";

const AuthRepository = Repository.get("AuthRepository");
const UserAccountRepository = Repository.get("UserAccountRepository");

export const authJWTService = {
  login,
  loginSocial,
  logout,
  resetPassword,
  changePasswordWithToken
};

function login(username, password) {

  return AuthRepository.login(JSON.stringify({username, password}))
    .then(response => {
      if (response.data.token) {
        axiosClient.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;
        localStorage.setItem('token', JSON.stringify(response.data));
        return UserAccountRepository.getMyAccount();
      }
    })
    .then((response) => {
      localStorage.setItem('currentUser', JSON.stringify(response.data));
      return response.data;
    })
    .catch((error) => {
      logout();
      const errorOut = (error && error.response.data.message) || error;
      return Promise.reject(errorOut);
    });
}

function loginSocial(token) {

  axiosClient.defaults.headers.common['Authorization'] = "Bearer " + token;
  localStorage.setItem('token', JSON.stringify({'token': token}));

  return UserAccountRepository.getMyAccount()
    .then((response) => {
      localStorage.setItem('currentUser', JSON.stringify(response.data));
      return response.data;
    })
    .catch((error) => {
      logout();
      const errorOut = (error && error.response.data.message) || error;
      return Promise.reject(errorOut);
    });
}

function resetPassword(email) {
  const payload = {"email": email};
  return UserAccountRepository.resetPassword(payload).then(response => {
    return response;
  }).catch((error) => {
    const errorOut = (error && error.response.data.message) || error;
    return Promise.reject(errorOut);
  });
}

function changePasswordWithToken(payload, token) {
  return UserAccountRepository.changePasswordWithToken(payload, token).then(response => {
    return response;
  }).catch((error) => {
    const errorOut = (error && error.response.data.message) || error;
    return Promise.reject(errorOut);
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
  localStorage.removeItem('currentUser');
}
